<template>
  <layout>
    <template slot="content">
      <div>
        <div class="page-header mb-3 mb-md-2">
          <h3 class="page-title">Agenda</h3>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Agenda</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Calendario
              </li>
            </ol>
          </nav>
        </div>

        <div class="row">
          <div class="col-lg-3">
            <div class="calendar-tasks mb-5">
              <h4 v-if="!agendaTasks.length" class="font-italic">Sin tareas...</h4>
              <ul class="calendar-tasks__list list-unstyled">
                <li v-for="(task, index) in agendaTasks" class="calendar-tasks__item calendar-tasks__item--task mb-3" :key="index">
                  <div class="card">

                    <div class="card-body">
                      <!-- <button class="btn btn-xs" @click.prevent="removeEvent(task.id)">
                        <i class="mdi mdi-close"></i>
                      </button> -->
                      <div class="calendar-tasks__icon">
                        <i class="mdi mdi-checkbox-marked-outline"></i>
                      </div>
                      <h4 class="card-title">
                        {{ task.title }}
                      </h4>
                      <p class="card-text">
                        {{ task.contentFull }}
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="mini-calendar">
              <vue-cal
                locale="es"
                class="vuecal--date-picker"
                xsmall
                hide-view-selector
                :time="false"
                :transitions="false"
                default-view="month"
                :disable-views="['week']"
                style="height: 300px">
              ></vue-cal>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="card">
              <div class="card-body">
                <vue-cal
                  locale="es"
                  :time-from="5 * 60"
                  :time-to="23 * 60"
                  default-view="month"
                  :events="events"
                  :show-all-day-events="true"
                  events-on-month-view="short"
                  :on-event-click="onEventClick"
                  :min-event-width="0"
                  :disable-views="['years', 'year']"
                  style="height: 85vh"
                ></vue-cal>
              </div>
            </div>
          </div>
        </div>

        <create-agenda-appointment></create-agenda-appointment>
        <edit-agenda-appointment v-if="selected" :payload="selected"></edit-agenda-appointment>
        <create-agenda-task></create-agenda-task>
        <edit-agenda-task v-if="selected" :payload="selected"></edit-agenda-task>
        <create-agenda-audience></create-agenda-audience>
        <edit-agenda-audience v-if="selected" :payload="selected"></edit-agenda-audience>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/BaseLayout'
import VueCal from 'vue-cal'
import CreateAgendaAppointment from '@/components/CreateAgendaAppointment'
import EditAgendaAppointment from '@/components/EditAgendaAppointment'
import CreateAgendaTask from '@/components/CreateAgendaTask'
import EditAgendaTask from '@/components/EditAgendaTask'
import CreateAgendaAudience from '@/components/CreateAgendaAudience'
import EditAgendaAudience from '@/components/EditAgendaAudience'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Agenda',
  data () {
    return {
      selected: {}
    }
  },
  computed: {
    ...mapGetters({
      events: 'Agenda/events'
    }),
    agendaTasks () {
      return this.events.filter((row) => {
        if (row.type === 2) {
          return row
        }
      })
    },
    agendaEvents () {
      return this.events.filter((row) => {
        if (row.type !== 2) {
          return row
        }
      })
    }
  },
  methods: {
    ...mapActions({
      getAllCalendarEvents: 'Agenda/getAllCalendarEvents',
      remove: 'Agenda/delete'
    }),
    ...mapMutations({
      TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL',
      TOGGLE_EDIT_AGENDA_TASK_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_TASK_MODAL',
      TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL: 'Agenda/TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL'
    }),
    onEventClick (event, e) {
      this.selected = event.raw
      if (this.selected.type === 1) {
        this.TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL(true)
      }
      if (this.selected.type === 2) {
        this.TOGGLE_EDIT_AGENDA_TASK_MODAL(true)
      }
      if (this.selected.type === 3) {
        this.TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL(true)
      }
      // this.$swal(event.title, event.contentFull ? event.contentFull : '', '')
      e.stopPropagation()
    },
    removeEvent (event) {
      this.remove({ id: event })
        .then(() => {
          this.getAllCalendarEvents()
        })
    }
  },
  mounted () {
    this.getAllCalendarEvents()
    this.$events.$on('table-item-edit', (data, label) => {
      this.selected = data
      if (this.selected.type === 1) {
        this.TOGGLE_EDIT_AGENDA_APPOINTMENT_MODAL(true)
      }
      if (this.selected.type === 2) {
        this.TOGGLE_EDIT_AGENDA_TASK_MODAL(true)
      }
      if (this.selected.type === 3) {
        this.TOGGLE_EDIT_AGENDA_AUDIENCE_MODAL(true)
      }
    })
  },
  beforeDestroy () {
    this.$events.off('table-item-edit')
  },
  components: {
    Layout,
    EditAgendaAppointment,
    CreateAgendaAppointment,
    CreateAgendaTask,
    EditAgendaTask,
    CreateAgendaAudience,
    EditAgendaAudience,
    VueCal
  }
}
</script>
